@import '../../../../../../Foundation/Styling/code/thule/global/media-queries.less';
@import '@{variablesLocation}';

.activity-slider-wrapper {
  position: relative;

  @media screen and (min-width: 1920px) {
    padding: 0 3%;
  }

  .header-wrapper {
    position: relative;
  }

  .header-wrapper,
  .activity-slider {
    padding: 0 @spacer6;

    @media screen and (min-width: @screen-lg) {
      padding: 0 3%;
    }

    @media screen and (min-width: 1920px) {
      padding: 0;
    }
  }

  .activity-slider {
    position: relative;
    overflow-x: scroll;
    display: flex;

    &::-webkit-scrollbar,
    &::-webkit-scrollbar-thumb,
    &::-webkit-scrollbar-track {
      height: 0;
    }
  }

  .activity-slider-arrows {
    display: flex;
    position: absolute;
    bottom: 0;
    right: 3%;
    gap: 1.2rem;

    @media screen and (min-width: 1920px) {
      right: 0;
    }

    .arrow {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 3.2rem;
      height: 3.2rem;
      background-color: @Gray10;
      cursor: pointer;

      &.disabled {
        cursor: default;
        i {
          background: @Gray40;
        }
      }
    }
  }

  .slider-item {
    position: relative;
    width: 28rem !important;
    min-width: 28rem !important;
    margin: 0 @spacer3;

    @media screen and (min-width: @screen-lg-min) {
      img {
        transition: transform 0.4s;
      }
      &:hover img {
        transform: scale(1.05);
      }
    }
    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }

    .img-container {
      background-color: black;
      height: 35rem;
      width: 28rem !important;
      min-width: 28rem !important;

      @media screen and (min-width: @screen-lg-min) {
        min-width: 32rem !important;
        width: 32rem !important;
        height: 40rem;
        overflow: hidden;
      }

      h3 {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: @White;
        text-align: center;
        text-shadow: 0px 4px 50px rgb(0 0 0 / 60%);
      }
    }

    @media screen and (min-width: @screen-lg-min) {
      min-width: 32rem !important;
      width: 32rem !important;
    }

    img {
      aspect-ratio: 4/5;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .tint {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: rgb(0 0 0 / 20%);
    }

    .intro-text {
      font-size: 14px;
      color: @Gray80;

      * {
        font-size: 14px;
        color: @Gray80;
        margin: 0;
      }
    }
  }
}
